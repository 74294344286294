// ** MUI Imports
import { Fade, TextField, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// ** Icon Imports
import Icon from "components/icon";
import { useAppSearch } from "context/appBarContext";
import { useAuth } from "utils/hooks/useAuth";
// import { useAppSearch } from 'context/appBarContext'

// ** Components
import ModeToggler from "utils/layouts/components/shared-components/ModeToggler";
import UserDropdown from "utils/layouts/components/shared-components/UserDropdown";
import UserSwitchDropdown from "utils/layouts/components/shared-components/UserSwitchDropdown";
import { useTheme } from "@mui/material/styles";
import { useRouter } from "next/router";
import GuideDropdown from "components/guide/GuideDropdown";
import IconifyIcon from "components/icon";
import Link from "next/link";

const AppBarContent = (props) => {
  const theme = useTheme();
  const router = useRouter();

  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props;
  const { searchValue, handleSearch, showSearch } = useAppSearch();
  const auth = useAuth();

  let path = window.location.href.split("/");

  const { user } = auth;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        className="actions-left"
        sx={{ mr: 2, display: "flex", alignItems: "center" }}
      >
        {hidden && !settings.navHidden ? (
          <IconButton
            color="inherit"
            sx={{ ml: -2.75 }}
            onClick={toggleNavVisibility}
          >
            <Icon icon="mdi:menu" />
          </IconButton>
        ) : null}
        {showSearch && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton color="inherit" sx={{ mr: 1, ml: -2.75 }}>
              <Icon icon="mdi:magnify" />
            </IconButton>
            <TextField
              id="searchBar"
              fullWidth
              // label="Search"
              placeholder="Search..."
              variant="standard"
              onChange={(e) => handleSearch(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              value={searchValue}
              sx={{ width: "100%" }}
              autoComplete={"off"}
            />
          </div>
        )}
      </Box>
      <Box
        className="actions-right"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <IconButton onClick={() => router.push("/personalized-share")}>
        <Tooltip
              arrow
              title={"Seamless Share"}
              placement="bottom"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 300 }}
            >
              <Typography>
                {" "}
                <IconifyIcon icon={"mdi-tools"} />
              </Typography>
            </Tooltip>
          
        </IconButton>
        <IconButton>
          <Link
            href="https://scraper.adaptivefunnels.ai"
            target="_blank"
            id="privacyLink"
          >
           <Tooltip
            arrow
            title={"Scriptify"}
            placement="bottom"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 300 }}
          >
            <Typography>
              {" "}
              <IconifyIcon icon={"mdi-test-tube"} />
            </Typography>
          </Tooltip>
          </Link>
        </IconButton>
        <GuideDropdown settings={settings} />
        {user?.agent && <UserSwitchDropdown settings={settings} />}
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  );
};

export default AppBarContent;

const Status = ({ value }) => {
  const colors = {
    active: "#4caf50",
    inactive: "#ff5252",
  };

  return (
    <span
      style={{
        color: "#fff",
        backgroundColor: colors[value] || "#9c27b0",
        width: 12,
        height: 12,
        display: "inline-block",
        borderRadius: 50,
      }}
    />
  );
};
