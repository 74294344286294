// ** React Imports
import { useState, Fragment, useEffect } from "react";

// ** Next Import
import { useRouter } from "next/router";

// ** MUI Imports
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

// ** Icon Imports
import Icon from "components/icon";

// ** Context
import { useAuth } from "utils/hooks/useAuth";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Select,
  TextField,
  capitalize,
} from "@mui/material";
import { userSwitchAPI } from "pages/api/user";
import { toast } from "react-hot-toast";
import { profileAPI } from "pages/api/auth";
import SelectField from "components/inputs/SelectField";
import { sliceContent } from "utils/helpers";
import { errorHandler } from "utils/errorHandler";
import OrganizationCreateForm from "components/forms/OrganizationCreateForm";
import { createOrganizationAPI } from "pages/api/technician";
import IconifyIcon from "components/icon";

// ** Styled Components
const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const UserSwitchDropdown = (props) => {
  // ** Props
  const { settings } = props;
  const auth = useAuth();
  // ** States
  const [anchorEl, setAnchorEl] = useState(null);
  const [switchList, setSwitchList] = useState([]);
  const [switchRadio, setSwitchRadio] = useState("");
  const [createFrom, setCreateFrom] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formError, setFormError] = useState(null);
  const [isHandlingSwitch, setIsHandlingSwitch] = useState(false);
  // ** Hooks
  const router = useRouter();
  const { user } = useAuth();

  useEffect(() => {
    setSwitchList(user?.allContractors);
    setSwitchRadio(user?.activeContractor?._id);
  }, [user]);

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url) => {
    if (url) {
      router.push("/ai-funnels");
      router.reload();
    }
    setAnchorEl(null);
  };

  const handleFormOpen = () => {
    setCreateFrom(true);
  };

  const handleFormClose = () => {
    setCreateFrom(false);
  };
  const handleSwitch = async (account) => {
    if (!isHandlingSwitch) {
      setIsHandlingSwitch(true);
      try {
        const { data } = await userSwitchAPI(account);
        toast.success("Account switched");
        setSwitchRadio(account);
        handleDropdownClose(window.location.pathname);
      } catch (error) {
        if (error?.response) {
          errorHandler(error.response);
        } else {
          errorHandler(error);
        }
      } finally {
        setIsHandlingSwitch(false);
      }
    }
  };

  const handleCreate = async (data) => {
    setIsSubmitting(true);
    setDisableBtn(true);
    createOrganizationAPI(data)
      .then(async ({ data }) => {
        auth.setLoading(true);
        toast.success(data?.messsage);
        setIsSubmitting(false);
        setFormError(null);
        handleFormClose();
        await auth.profilReload();
        router.push("/pricing");
      })
      .catch((error) => {
        setIsSubmitting(false);
        setFormError(error?.response?.data?.message);
        setDisableBtn(false);
        if (error?.response) {
          errorHandler(error.response);
        } else {
          errorHandler(error);
        }
      });
  };

  const handleChange = (e) => {
    handleSwitch(e);
  };

  const styles = {
    py: 2,
    px: 4,
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "text.primary",
    textDecoration: "none",
    "& svg": {
      mr: 2,
      fontSize: "1.375rem",
      color: "text.primary",
    },
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          // pointerEvents:
          //   user?.allContractors?.length == 1 ||
          //     user?.allContractors?.length == 0
          //     ? "none"
          //     : "auto",
        }}
        aria-haspopup="true"
        onClick={handleDropdownOpen}
      >
        <Typography variant="body1" sx={{ fontWeight: 500, mr: 2 }}>
          {sliceContent(user?.activeContractor?.name, 10)}
        </Typography>
        {user?.allContractors?.length > 1 && (
          <IconButton color="inherit">
            <Icon icon="mdi-menu-down" />
          </IconButton>
        )}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ "& .MuiMenu-paper": { width: 230, mt: 4 } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: direction === "ltr" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: direction === "ltr" ? "right" : "left",
        }}
      >
        <RadioGroup
          value={switchRadio}
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          {switchList?.map((item, index) => (
            <FormControlLabel
              onClick={(event) => {
                event.stopPropagation();
                switchRadio !== item?._id && handleSwitch(item?._id);
              }}
              control={<Radio />}
              value={item?._id}
              key={index}
              sx={{ m: 0 }}
              label={
                <Box sx={styles}>
                  <Typography variant="body1">
                    {item?.name && sliceContent(capitalize(item?.name), 15)}
                  </Typography>
                </Box>
              }
            />
          ))}
        </RadioGroup>
        <Divider />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, p: 2 }}>
          <Button
            variant="contained"
            fullWidth
            size="medium"
            onClick={handleFormOpen}
            id="add-organization"
            startIcon={<IconifyIcon icon="mdi-plus" />}
          >
            Add Organization
          </Button>
          {/* <Button
            variant="outlined"
            fullWidth
            size="medium"
            onClick={() => {
              handleSwitch();
            }}
            disabled={user?.activeContractor?._id === switchRadio}
            startIcon={<IconifyIcon icon="mdi-sync" />}
            id="switch-account"
          >
            Switch
          </Button> */}
        </Box>
      </Menu>
      {createFrom && (
        <OrganizationCreateForm
          isOpen={createFrom}
          disableBtn={disableBtn}
          setDisableBtn={setDisableBtn}
          formError={formError}
          handleClose={handleFormClose}
          handleCreate={handleCreate}
          isSubmitting={isSubmitting}
        />
      )}
    </Fragment>
  );
};

export default UserSwitchDropdown;
